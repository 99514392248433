.d3-loader-container {
	width: 100%;
	height: 100%;
	position: relative;
	.loader {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
	    border-radius: 50%;
	    animation: spin 0.5s linear infinite;
	    @keyframes spin {
	    	0% {
	    		transform: rotate(0deg);
	    	}
	    	100% {
	    		transform: rotate(360deg);
	    	}

		}
	}

}