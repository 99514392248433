.24_130 {
  overflow: hidden;
}
.e24_130 {
  width: 428px;
  height: 597px;
  position: absolute;
}
.e5_33 {
  background-color: rgba(255, 255, 255, 1);
  width: 428px;
  height: 597px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.e5_34 {
  width: 436px;
  height: 1638px;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url(../images/victorian_2_bkg_border_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e5_35 {
  width: 48px;
  height: 49px;
  position: absolute;
  left: 39px;
  top: 20px;
  background-image: url(../images/victorian_2_logo_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e5_32 {
  color: rgba(229.00000154972076, 167.00000524520874, 71.00000336766243, 1);
  width: 252px;
  height: 45px;
  position: absolute;
  left: 88px;
  top: 89px;
  font-family: Noto Sans SC;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0;
}
.e15_50 {
  width: 330px;
  height: 107px;
  position: absolute;
  left: 47px;
  top: 204px;
}
.e6_8 {
  background-color: rgba(
    238.00000101327896,
    165.00000536441803,
    41.00000135600567,
    0.20000000298023224
  );
  width: 330px;
  height: 107px;
  position: absolute;
  left: 0px;
  top: 0px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.e6_2 {
  color: rgba(0, 0, 0, 1);
  width: 286px;
  height: 55px;
  position: absolute;
  left: 24px;
  top: 26px;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e15_45 {
  color: rgba(0, 0, 0, 1);
  width: 286px;
  height: 116px;
  position: absolute;
  left: 71px;
  top: 369px;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.15_47 {
  border: 1px solid
    rgba(229.00000154972076, 167.00000524520874, 71.00000336766243, 1);
}
.e15_47 {
  width: 244px;
  height: 0px;
  position: absolute;
  left: 90px;
  top: 154px;
}
.15_48 {
  border: 1px solid
    rgba(229.00000154972076, 167.00000524520874, 71.00000336766243, 1);
}
.e15_48 {
  width: 244px;
  height: 0px;
  position: absolute;
  left: 90px;
  top: 347px;
}
.15_49 {
  border: 1px solid
    rgba(229.00000154972076, 167.00000524520874, 71.00000336766243, 1);
}
.e15_49 {
  width: 244px;
  height: 0px;
  position: absolute;
  left: 90px;
  top: 509px;
}
.e22_101 {
  transform: rotate(9.079638395640298deg);
  width: 52.52983856201172px;
  height: 52.52983856201172px;
  position: absolute;
  left: 192px;
  top: 167px;
  background-image: url(../images/confetti_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
