@import 'base';
@import './d3/common.scss';
@import './d3/colors.scss';

html {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
table,
p,
div,
a,
span,
button,
textarea,
input,
ul,
li {
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  background: transparent;
}

a:hover {
  cursor: pointer;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// Universal Styles

html,
body {
  background: $linkt-bg !important;
}

body {
  overflow-x: auto !important;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-family: 'aller', sans-serif !important;
  font-style: normal;
  -moz-osx-font-smoothing: antialiased;
  color: $dark-text !important;
  font-size: 18px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }
  h1 {
    margin-bottom: 32px;
  }
  p {
    margin-bottom: 10px;
  }
  input,
  textarea {
    outline: none;
    border: 1px solid $grey-3;
    padding: 5px 10px;
  }
  label {
    display: block;
  }
  a {
    cursor: pointer;
  }
}

.table-header {
  background: $grey-5;
  padding: 10px;
  height: 40px;
  h5 {
    color: $dark-grey-text;
    font-size: 18px;
    font-weight: 500;
    margin: 0px;
    display: inline-block;
  }
}

.noty_bar {
  border: none !important;
  border-radius: 0px !important;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
  .noty_body {
    color: black !important;
    font-weight: 300 !important;
    font-size: 14px;
  }
  .noty_close_button {
    background: none;
    color: $error-red;
    font-size: 20px;
  }
}

.noty_buttons {
  padding: 0px 0px 10px 10px !important;
  .noty-btn {
    border: none;
    outline: none;
    color: $error-red;
    background: transparent;
    &:hover {
      cursor: pointer;
    }
  }
}

.search-bar {
  position: absolute;
  right: 80px;
  input {
    font-size: 18px;
    font-weight: 300;
    width: 160px;
    background: transparent;
    border: 0px;
    &::-webkit-input-placeholder {
      color: $grey-3;
    }
    &:focus {
      outline: none;
    }
  }
  button {
    border: none;
    background: transparent;
    color: $survey-blue;
    i {
      font-size: 18px;
    }
  }
  border-bottom: 1px solid $survey-blue;
}

.page-selector {
  display: inline;
  text-align: right;
  .fa {
    display: inline;
    font-size: 16px;
  }
  .fa-arrow-left,
  .fa-arrow-right,
  span {
    user-select: none;
    padding: 0px 10px;
    color: $blue-grey;
    &:hover {
      cursor: pointer;
    }
  }
  i.disabled {
    visibility: hidden;
  }
  span {
    color: $grey-2;
    &.active {
      color: black;
      &:hover {
        cursor: default;
      }
    }
  }
}

.custom-input {
  border: none;
  border-bottom: 1px solid $survey-aqua;
  border-radius: 0px;
  font-weight: 300;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: $grey-3;
  }
}

.clickable:hover {
  cursor: pointer;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

.ellipsed {
  overflow-x: hidden;
  whitespace: nowrap;
  text-overflow: ellipsis;
}

.nowrap {
  white-space: nowrap;
}
