@import './colors.scss';

.bold {
  font-weight: 700;
}

.ellipsify {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0px;
}

.fa-icon-before {
  position: relative;
  &:before {
    position: absolute;
    font-family: FontAwesome;
  }
}

.fa-icon-after {
  position: relative;
  &:after {
    position: absolute;
    font-family: FontAwesome;
  }
}

.responsive-border {
  box-shadow: 1px 0 0 0 $grey-border, 0 1px 0 0 $grey-border,
    1px 1px 0 0 $grey-border, 1px 0 0 0 $grey-border inset,
    0 1px 0 0 $grey-border inset;
}

.border-top {
  box-shadow: 1px 0 0 0 $grey-border;
}

$h1-size: 28px;
$h2-size: 22px;
$h3-size: 20px;

$common-font-size: 18px;

.h1-text,
.h2-text,
.h3-text {
  font-weight: bold;
}

body {
  font-size: $common-font-size;
  .h1-text,
  h1 {
    font-size: $h1-size;
  }
  .h2-text,
  h2 {
    font-size: $h2-size;
  }
  .h3-text,
  h3 {
    font-size: $h3-size;
  }
}

.text-common {
  font-size: $common-font-size;
}

.info-popup {
  @extend .text-common;
  color: $text-color !important;
  background-color: $grey-5 !important;
  font-size: 10px !important;
  border-radius: 0 !important;
}

.absolute-center-all {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.absolute-center-vertical {
  @extend .absolute-center-all;
  left: unset;
  right: unset;
  margin: auto 0px;
}

.absolute-center-horizontal {
  @extend .absolute-center-all;
  top: unset;
  bottom: unset;
  margin: 0px auto;
}

.flexed-header,
.flexed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .custom-button {
    margin: 0px 0px 0px 15px;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.center-justified {
    justify-content: center;
  }
  &.end-justified {
    justify-content: flex-end;
  }
  &.start-justified {
    justify-content: flex-start;
  }
  &.column {
    flex-direction: column;
  }
  &.start-aligned {
    align-items: flex-start;
  }
  &.end-aligned {
    align-items: flex-end;
  }
  &.center-aligned {
    align-items: center;
  }
}

.flexed-header {
  padding: 15px 0px;
  &.sticky {
    position: sticky;
    z-index: 2;
    background: $grey-5;
  }
}

.button-list {
  display: flex;
  align-items: center;
  .custom-button:first-of-type {
    margin-left: 0px;
  }
  &.flex-end {
    justify-content: flex-end;
    .custom-button:last-of-type {
      margin-right: 0px;
    }
  }
}

.page {
  padding: 30px 28px;
  margin: 0 auto;
  min-width: 800px;
  .linkt-container > * {
    margin-bottom: 15px;
  }
  .linkt-container {
    background: white;
    padding: 24px 40px;
    margin: 8px auto 20px auto;
  }
}

@media only screen and (max-width: 768px) {
  .page {
    min-width: 360px !important;
    width: 100%;
  }
}
