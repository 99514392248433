.24_131 {
  overflow: hidden;
}
.e24_131 {
  width: 428px;
  height: 1165px;
  position: absolute;
}
.e9_2 {
  background-color: rgba(255, 255, 255, 1);
  width: 428px;
  height: 1165px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.e9_3 {
  width: 436px;
  height: 1638px;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url(../images/victorian_2_bkg_border_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e9_4 {
  width: 48px;
  height: 49px;
  position: absolute;
  left: 39px;
  top: 20px;
  background-image: url(../images/victorian_2_logo_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e9_5 {
  color: rgba(229.00000154972076, 167.00000524520874, 71.00000336766243, 1);
  width: 258px;
  height: 29px;
  position: absolute;
  left: 50px;
  top: 92px;
  font-family: Noto Sans SC;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0;
}
.e13_2 {
  color: rgba(0, 0, 0, 1);
  width: 315px;
  height: 56px;
  position: absolute;
  left: 50px;
  top: 188px;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.14_9 {
  border: 1px solid
    rgba(229.00000154972076, 167.00000524520874, 71.00000336766243, 1);
}
.e14_9 {
  width: 244px;
  height: 0px;
  position: absolute;
  left: 50px;
  top: 271px;
}
.14_10 {
  border: 1px solid
    rgba(229.00000154972076, 167.00000524520874, 71.00000336766243, 1);
}
.e14_10 {
  width: 244px;
  height: 0px;
  position: absolute;
  left: 50px;
  top: 161px;
}
.e13_3 {
  color: rgba(0, 0, 0, 1);
  width: 303px;
  height: 21px;
  position: absolute;
  left: 50px;
  top: 298px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e14_6 {
  color: rgba(0, 0, 0, 1);
  width: 113px;
  height: 16px;
  position: absolute;
  left: 153px;
  top: 432px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e14_5 {
  color: rgba(0, 0, 0, 1);
  width: 65px;
  height: 16px;
  position: absolute;
  left: 153px;
  top: 394px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e14_7 {
  color: rgba(0, 0, 0, 1);
  width: 205px;
  height: 46px;
  position: absolute;
  left: 153px;
  top: 466px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e15_14 {
  color: rgba(0, 0, 0, 1);
  width: 140px;
  height: 16px;
  position: absolute;
  left: 127px;
  top: 359px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e15_18 {
  color: rgba(0, 0, 0, 1);
  width: 253px;
  height: 36px;
  position: absolute;
  left: 127px;
  top: 537px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e15_19 {
  color: rgba(0, 0, 0, 1);
  width: 110px;
  height: 16px;
  position: absolute;
  left: 127px;
  top: 638px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e15_20 {
  color: rgba(0, 0, 0, 1);
  width: 235px;
  height: 52px;
  position: absolute;
  left: 127px;
  top: 720px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e15_22 {
  color: rgba(0, 0, 0, 1);
  width: 234px;
  height: 56px;
  position: absolute;
  left: 127px;
  top: 818px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e15_52 {
  color: rgba(0, 0, 0, 1);
  width: 160px;
  height: 21px;
  position: absolute;
  left: 50px;
  top: 121px;
  font-family: Noto Sans SC;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e23_106 {
  width: 51px;
  height: 60px;
  position: absolute;
  left: 57px;
  top: 337px;
  background-image: url(../images/med_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e23_107 {
  width: 51px;
  height: 60px;
  position: absolute;
  left: 57px;
  top: 520px;
  background-image: url(../images/pills_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e23_108 {
  width: 51px;
  height: 60px;
  position: absolute;
  left: 57px;
  top: 616px;
  background-image: url(../images/asset_2_2.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e15_16 {
  border-radius: 500px;
  background-color: rgba(
    229.00000154972076,
    167.00000524520874,
    71.00000336766243,
    1
  );
  width: 6px;
  height: 6px;
  position: absolute;
  left: 135px;
  top: 400px;
}
.e17_53 {
  border-radius: 500px;
  background-color: rgba(
    229.00000154972076,
    167.00000524520874,
    71.00000336766243,
    1
  );
  width: 6px;
  height: 6px;
  position: absolute;
  left: 135px;
  top: 438px;
}
.e17_54 {
  border-radius: 500px;
  background-color: rgba(
    229.00000154972076,
    167.00000524520874,
    71.00000336766243,
    1
  );
  width: 6px;
  height: 6px;
  position: absolute;
  left: 135px;
  top: 471px;
}
.e23_109 {
  width: 51px;
  height: 60px;
  position: absolute;
  left: 57px;
  top: 712px;
  background-image: url(../images/hands_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e23_110 {
  width: 51px;
  height: 60px;
  position: absolute;
  left: 57px;
  top: 808px;
  background-image: url(../images/needle_2.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.15_51 {
  border: 1px solid
    rgba(229.00000154972076, 167.00000524520874, 71.00000336766243, 1);
}
.e15_51 {
  width: 226px;
  height: 0px;
  position: absolute;
  left: 50px;
  top: 901px;
}
.e15_44 {
  width: 336px;
  height: 143px;
  position: absolute;
  left: 46px;
  top: 920px;
}
.e15_40 {
  background-color: rgba(
    238.00000101327896,
    165.00000536441803,
    41.00000135600567,
    0.20000000298023224
  );
  width: 336px;
  height: 109px;
  position: absolute;
  left: 0px;
  top: 34px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.e15_41 {
  color: rgba(0, 0, 0, 1);
  width: 257px;
  height: 33px;
  position: absolute;
  left: 40px;
  top: 75px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e15_42 {
  width: 57px;
  height: 65px;
  position: absolute;
  left: 139px;
  top: 0px;
  background-image: url(../images/asset_1_2.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.19_96 {
  border: 1px solid
    rgba(229.00000154972076, 167.00000524520874, 71.00000336766243, 1);
}
.e19_96 {
  width: 226px;
  height: 0px;
  position: absolute;
  left: 50px;
  top: 1116px;
}
