@import './colors.scss';

.rect-marker {
  height: 8px;
  width: 64px;
  margin-bottom: 18px;
  &.magenta {
    background: $magenta;
  }
  &.purple {
    background: $purple;
    &.p-2 {
      background: $purple-2;
    }
  }
  &.peach {
    background: $peach;
  }
  &.pink {
    &.p3 {
      background: $pink-3;
    }
  }
  &.deep-lavender {
    background: $deep-lavender;
  }
  &.divider {
    height: 1px;
    width: 100%;
    margin-bottom: 0px;
    margin-left: 10px;
    &.pink1 {
      background: $pink-1;
    }
    &.purple {
      background: $purple-border;
    }
    &.deep-lavender {
      background: $deep-lavender;
    }
  }
}
