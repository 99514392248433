* {
  box-sizing: border-box;
}
body {
  font-size: 14px;
}
.v3_2 {
  width: 428px;
  height: 2620px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: relative;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v7_48 {
  width: 428px;
  height: 100%;
  background: rgba(191, 219, 235, 1);
  opacity: 1;
  position: relative;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v7_51 {
  width: 388px;
  height: 804px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 991px;
  left: 20px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.v7_53 {
  width: 390px;
  height: 264px;
  background: rgba(253, 255, 171, 1);
  opacity: 1;
  position: absolute;
  top: 2257px;
  left: 19px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.v7_49 {
  width: 388px;
  height: 327px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 57px;
  left: 20px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.v7_40 {
  width: 72px;
  height: 72px;
  background: url('../images/v7_40.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 15px;
  left: 30px;
  overflow: hidden;
}
.v9_98 {
  width: 388px;
  height: 244px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 1893px;
  left: 21px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.v9_99 {
  width: 72px;
  height: 72px;
  background: url('../images/v9_99.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 1860px;
  left: 31px;
  overflow: hidden;
}
.v6_16 {
  width: 347px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 1951px;
  left: 52px;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v6_17 {
  width: 334px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 2327px;
  left: 49px;
  font-family: Aller;
  font-weight: Bold;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v8_63 {
  width: 123px;
  height: 103px;
  background: url('../images/v8_63.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 2205px;
  left: 5px;
  overflow: hidden;
}
.name {
  color: #fff;
}
.name {
  color: #fff;
}
.name {
  color: #fff;
}
.v8_60 {
  width: 59px;
  height: 59px;
  background: url('../images/v8_60.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 1216px;
  left: 51px;
  overflow: hidden;
}
.v6_12 {
  width: 241px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 1222px;
  left: 11px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v7_41 {
  width: 59px;
  height: 59px;
  background: url('../images/v7_41.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 1355px;
  left: 51px;
  overflow: hidden;
}
.v6_13 {
  width: 244px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 1361px;
  left: 11px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v9_89 {
  width: 69px;
  height: 82px;
  background: url('../images/v9_99.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 1501px;
  left: 46px;
  overflow: hidden;
}
.v9_90 {
  width: 7px;
  height: 3px;
  background: rgba(252, 225, 161, 1);
  opacity: 1;
  position: absolute;
  top: 12px;
  left: 19px;
  border: 1px solid rgba(252, 225, 161, 1);
}
.v9_91 {
  width: 1px;
  height: 9px;
  background: rgba(246, 225, 178, 1);
  opacity: 1;
  position: absolute;
  top: 39px;
  left: 53px;
  overflow: hidden;
}
.v9_92 {
  width: 40px;
  height: 55px;
  background: linear-gradient(rgba(255, 228, 163, 1), rgba(241, 180, 52, 0));
  opacity: 1;
  position: absolute;
  top: 16px;
  left: 14px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}
.v9_93 {
  width: 69px;
  height: 82px;
  background: url('../images/v9_93.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v6_14 {
  width: 258px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 1519px;
  left: 11px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v7_46 {
  width: 80px;
  height: 67px;
  background: url('../images/v7_46.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 1665px;
  left: 39px;
  overflow: hidden;
}
.v6_15 {
  width: 222px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 1685px;
  left: 11px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v10_117 {
  width: 222px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 1685px;
  left: 11px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v16_61 {
  width: 376px;
  height: 218px;
  background: url('../images/v16_61.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 507px;
  left: 26px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.v10_119 {
  width: 340px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 755px;
  left: 38px;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v6_8 {
  width: 282px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 448px;
  left: 42px;
  font-family: Aller;
  font-weight: Bold;
  font-size: 22px;
  opacity: 1;
  text-align: left;
}
.v6_10 {
  width: 348px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 1020px;
  left: 59px;
  font-family: Aller;
  font-weight: Bold;
  font-size: 22px;
  opacity: 1;
  text-align: left;
}
.v6_11 {
  width: 288px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 1076px;
  left: 59px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v6_7 {
  width: 334px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 106px;
  left: 59px;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
