@import './colors.scss';

.container {
  background: $white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 25px 14px 35px;
  margin: 0 -14px 25px;
  position: relative;
  .header-icon {
    position: absolute;
  }
  p {
    white-space: pre-wrap;
  }
  section {
    padding: 28px 0 0;
    &:first-child {
      padding-top: 0;
    }
    &:not(:last-child) {
      padding-bottom: 28px;
    }
    &.less-padding {
      padding: 20px 0 0;
    }
    &.less-padding-top {
      padding-top: 10px;
    }
  }
  .center-icon {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }
}

.purple {
  .container {
    section {
      &:not(:last-child) {
        border-bottom: 1px solid $purple-border;
      }
    }
  }
}
.magenta {
  .container {
    section {
      &:not(:last-child) {
        border-bottom: 1px solid $light-magenta-bg;
      }
    }
  }
}

.pink-1 {
  .container {
    section {
      &:not(:last-child) {
        border-bottom: 1px solid $pink-1;
      }
    }
  }
}

.container + .container {
  margin-top: -5px;
}

.container {
  &.meditation-container-1 {
    section {
      padding-top: 0;
    }
    border-left: 6px solid $deep-lavender;
  }
  &.meditation-container-2 {
    padding-bottom: 0px;
    section {
      padding-top: 0px;
    }
    div {
      height: 82px;
      text-align: center;
    }
  }
}

.rtl {
  .container {
    &.meditation-container-1 {
      border-right: 6px solid $deep-lavender;
      border-left: 0;
    }
  }
}
