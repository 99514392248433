$text-color: #4a4a4a;
$input-color: #000000;
$navbar-text: #767676;
$white: #ffffff;
$purple-border: #e4d6ea;
$light-purple-bg: hsl(282, 32%, 88%);
$purple: #653379;
$purple-2: #ac4fc6;
$light-peach-bg: #ffe7e7;
$peach: #ff6d6a;
$light-magenta-bg: #fce5f8;
$magenta: #bb16a3;
$pink-1: #f3dbf8;
$pink-2: #ffecfe;
$pink-3: #e7bae4;
$deep-lavender: #9678d3;
$lavender: #e8e2f5;

$navbar-text: #767676;
$navbar-text-active: #34495e;
$metadata-field-color: #000000;
$toggle-grey: #f4f4f4;
$grey-text: #858386;
$light-grey-text: #1b1c1d;
$dark-text: #1b1c1d;
$light-text: #545454;

$leaf-green: #7cb342;
$survey-gold: #f9a825;
$blue-grey: #879b9c;
$dark-grey-text: #525e67;
$button-blue: #2987cd;
$survey-aqua: #1ea896;
$survey-dark-aqua: #018573;
$survey-blue: #77c4fe;
$survey-blue-dark: #107bd5;
$survey-blue-active: #bbdefb;
$light-orange: #f8b685;

$metadata-button-border: #26a69a;
$metadata-underline: #1ea896;
$bright-turquoise: #80cbc4;

$primary-1: #0f2e22;
$primary-2: #1f5d44;
$primary-3: #3db988;
$primary-4: #8bd5b8;

$secondary-1: #0d60a6;
$secondary-2: #107bd5;
$secondary-3: #89c4f6;
$secondary-4: #eef6fc;

$grey-border: #cacaca;

$grey-1: #545454;
$grey-7: #737373;
$grey-2: #9b9b9b;
$grey-3: #cacaca;
$grey-4: #e5e8ea;
$grey-5: #f4f4f4;
$grey-6: #fafafa;
$grey-8: #e0e0e0;

$feedback-info: #d2e6f7;
$feedback-success: #abdcd5;
$feedback-warning: #f8b685;
$feedback-error: #e88d89;

$error-red: #cb2d2d;
$error-bg: #ffe3e3;

$no-active-data: #e88d89;
$no-passive-data: #f8b685;
$compliant: #d6eeeb;

$percent-meter-bg: #eceff0;

// New UI Colors --------------
$new-navbar: #052947;
$primary-1-v2: #313d98;
$primary-2-v2: #ecf6f8;
$secondary-1-v2: #009782;
$response-rate: #f27b21;
$separator-line: #f2f2f2;
$sidebar: #222222;
// $sidebar-link-selected: rgba(255, 255, 255, 0.24);
$sidebar-link-selected: rgba(255, 255, 255, 0.1);
$sidebar-link-hover: rgba(255, 255, 255, 0.34);

// Linkt
$linkt-light-blue: #a2ddf1;
$survey-bg: #fcfbf9;
$linkt-primary-2: #00b9cf;
$linkt-primary-2-active: #0692a2;
$linkt-grey-active: #e5e5e5;
$linkt-blue: #183b64;
$login-border: #dddddd;
$choice-border: #e8e8e8;
$choice-hover-bg: #f8f8f8;
$meter-bg: rgba(186, 186, 186, 0.13);
$linkt-bg: #eff5f4;
$survey-tag: #ffe8d6;
$article-tag: #e2f9f6;
$humi-border: #ebebeb;
$unread-inbox-circle: #009681;
$linkt-divider: #d5d6d6;
$disabled-grey: #d1d5db;
$success-bg: #e0f4ec;
$success-text: #2e8b66;

// Shadow colors
$box-shadow: rgba(0, 0, 0, 0.2);
