* {
  box-sizing: border-box;
}
body {
  font-size: 14px;
}
.v14_2 {
  width: 428px;
  height: 2783px;
  background: rgba(193, 140, 203, 1);
  opacity: 1;
  position: relative;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v10_116 {
  width: 387px;
  height: 301px;
  background: rgba(253, 255, 171, 1);
  opacity: 1;
  position: absolute;
  top: 2382px;
  left: 22px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.v7_54 {
  width: 386px;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 73px;
  left: 21px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.v9_100 {
  width: 386px;
  height: 158px;
  background: rgba(223, 214, 229, 0.4000000059604645);
  opacity: 1;
  position: absolute;
  top: 73px;
  left: 21px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.v10_115 {
  width: 383px;
  height: 279px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 1972px;
  left: 22px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.v6_18 {
  width: 385px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 121px;
  left: 19px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 28px;
  opacity: 1;
  text-align: center;
}
.v6_19 {
  width: 323px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 167px;
  left: 45px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 22px;
  opacity: 1;
  text-align: center;
}
.v6_20 {
  width: 294px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 274px;
  left: 50px;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v6_21 {
  width: 237px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 466px;
  left: 136px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v6_23 {
  width: 246px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 596px;
  left: 136px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v6_24 {
  width: 246px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 777px;
  left: 136px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v7_55 {
  width: 80px;
  height: 67px;
  background: url('../images/v7_55.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 464px;
  left: 40px;
  overflow: hidden;
}
.v7_39 {
  width: 81px;
  height: 68px;
  background: url('../images/v7_39.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 605px;
  left: 45px;
  overflow: hidden;
}
.v8_57 {
  width: 79px;
  height: 66px;
  background: url('../images/v8_57.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 771px;
  left: 41px;
  overflow: hidden;
}
.name {
  color: #fff;
}
.name {
  color: #fff;
}
.name {
  color: #fff;
}
.name {
  color: #fff;
}
.name {
  color: #fff;
}
.name {
  color: #fff;
}
.name {
  color: #fff;
}
.name {
  color: #fff;
}
.v7_33 {
  width: 44px;
  height: 37px;
  background: url('../images/v7_33.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 274px;
  left: 351px;
  overflow: hidden;
}
.v7_45 {
  width: 75px;
  height: 63px;
  background: url('../images/v7_45.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 925px;
  left: 49px;
  overflow: hidden;
}
.v6_25 {
  width: 227px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 923px;
  left: 136px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v8_75 {
  width: 73px;
  height: 62px;
  /* background: url('../images/v8_75.png'); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 1082px;
  left: 48px;
  overflow: hidden;
}
.v8_67 {
  width: 73px;
  height: 62px;
  background: url('../images/v8_67.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v8_70 {
  width: 51px;
  height: 23px;
  background: url('../images/v8_70.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 17px;
  left: 10px;
  overflow: hidden;
}
.name {
  color: #fff;
}
.v6_26 {
  width: 235px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 1066px;
  left: 136px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v8_76 {
  width: 79px;
  height: 79px;
  background: url('../images/v8_76.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 1230px;
  left: 46px;
  overflow: hidden;
}
.v6_27 {
  width: 260px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 1249px;
  left: 136px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v8_78 {
  width: 59px;
  height: 71px;
  background: url('../images/v8_78.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 1387px;
  left: 52px;
  overflow: hidden;
}
.v6_28 {
  width: 237px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 1391px;
  left: 136px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v8_77 {
  width: 113px;
  height: 83px;
  background: url('../images/v8_77.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 1549px;
  left: 25px;
  overflow: hidden;
}
.v6_29 {
  width: 249px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 1570px;
  left: 136px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v6_30 {
  width: 263px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 1705px;
  left: 136px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v8_59 {
  width: 82px;
  height: 69px;
  background: url('../images/v8_59.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 1705px;
  left: 41px;
  overflow: hidden;
}
.v6_31 {
  width: 306px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 2055px;
  left: 52px;
  font-family: Aller;
  font-weight: Regular;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v6_32 {
  width: 333px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 2472px;
  left: 52px;
  font-family: Aller;
  font-weight: Bold;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}
.v7_37 {
  width: 132px;
  height: 132px;
  background: url('../images/v7_37.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 2316px;
  left: 150px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  overflow: hidden;
}
.v14_38 {
  width: 84px;
  height: 81px;
  /* background: url('../images/v14_38.png'); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 29px;
  left: 172px;
  overflow: hidden;
}
.v14_7 {
  width: 84px;
  height: 76px;
  background: linear-gradient(rgba(81, 142, 87, 1), rgba(191, 214, 180, 1));
  opacity: 1;
  position: absolute;
  top: 5px;
  left: 0px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  overflow: hidden;
}
.v14_8 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 24px;
  left: 5px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_10 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 24px;
  left: 21px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_11 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 24px;
  left: 37px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_12 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 24px;
  left: 53px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_13 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 24px;
  left: 69px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_14 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 38px;
  left: 5px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_15 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 38px;
  left: 21px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_16 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 38px;
  left: 37px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_17 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 38px;
  left: 53px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_18 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 38px;
  left: 69px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_19 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 52px;
  left: 5px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_20 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 52px;
  left: 21px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_21 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 52px;
  left: 37px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_22 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 52px;
  left: 53px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_23 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 52px;
  left: 69px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_24 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 66px;
  left: 5px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_25 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 66px;
  left: 21px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_26 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 66px;
  left: 37px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_27 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 66px;
  left: 53px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.v14_28 {
  width: 9px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 66px;
  left: 69px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  overflow: hidden;
}
.name {
  color: #fff;
}
.v14_31 {
  width: 4px;
  height: 11px;
  background: rgba(28, 68, 37, 1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 15px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  overflow: hidden;
}
.v14_37 {
  width: 4px;
  height: 11px;
  background: rgba(28, 68, 37, 1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 64px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  overflow: hidden;
}
.v14_58 {
  width: 85px;
  height: 133px;
  /* background: url('../images/v14_58.png'); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 1900px;
  left: 162px;
  overflow: hidden;
}
.v14_56 {
  width: 20px;
  height: 16px;
  background: rgba(86, 64, 52, 1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 13px;
}
.v14_40 {
  width: 85px;
  height: 120px;
  background: linear-gradient(
    rgba(255, 237, 231, 1),
    rgba(220, 178, 156, 0.9900000095367432)
  );
  opacity: 1;
  position: absolute;
  top: 13px;
  left: 0px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow: hidden;
}
.v14_41 {
  width: 36px;
  height: 37px;
  background: url('../images/v14_41.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 54px;
  left: 6px;
  overflow: hidden;
}
.name {
  color: #fff;
}
.v14_53 {
  width: 40px;
  height: 16px;
  background: rgba(86, 63, 52, 1);
  opacity: 1;
  position: absolute;
  top: 64px;
  left: 45px;
}
.v14_55 {
  width: 8px;
  height: 8px;
  background: rgba(238, 209, 196, 1);
  opacity: 1;
  position: absolute;
  top: 68px;
  left: 51px;
  border-radius: 50%;
}
.v16_62 {
  width: 100px;
  height: 100px;
  /* background: url('../images/v16_62.png'); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 2816px;
  left: 52px;
  overflow: hidden;
}
