@import '../../styles/d3/colors.scss';

.layout-container {
  min-height: 100%;
  height: 100%;
  .core-layout__viewport {
    display: flex;
    width: 100%;
    position: relative;
  }
}

@media only screen and (max-width: 768px) {
  .layout-container {
    min-width: 0 !important;
    width: 100%;
  }
  .humi-lab {
    display: none;
  }
}
