@import './colors.scss';

.rtl {
  direction: rtl;
  .article {
    h2 {
      img {
        margin-right: 0 !important;
        margin-left: 11px;
      }
    }
    img {
      &.mr.width-music {
        margin-left: 24px;
        margin-right: 12px;
      }
    }
    .container {
      section {
        &.flexed {
          img {
            &.left {
              margin-right: 0px;
              margin-left: 11px;
            }
          }
        }
      }
      img {
        &.mr {
          margin-right: 0;
          margin-left: 10px;
        }
      }
    }
  }
}

.article {
  display: flex;
  flex-direction: column;
  word-break: break-word;
  &.purple {
    background: $light-purple-bg;
    .has-color {
      color: $purple;
    }
    .container {
      section {
        .section-divide {
          h1 {
            color: $purple;
          }
        }
      }
    }
  }
  &.peach {
    background: $light-peach-bg;
    .has-color {
      color: $peach;
    }
    h2 {
      &.contained {
        background: $peach;
      }
    }
  }
  &.magenta {
    background: $light-magenta-bg;
    .has-color {
      color: $magenta;
    }
  }
  &.pink-1 {
    background: $pink-1;
  }
  &.lavender {
    background: $lavender;
  }
  &.pink-2 {
    background: $pink-2;
  }

  h1 {
    max-width: 80%;
  }
  h2 {
    &.contained {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      color: $white;
      margin-bottom: 15px;
      padding: 7px 10px 9px;
      width: fit-content;
    }
  }
  .container {
    section {
      &.flexed {
        img {
          &.left {
            margin-right: 11px;
          }
        }
      }
      &.no-border {
        border: 0px;
        padding-top: 0;
        &:not(:last-child) {
          padding-bottom: 8px;
        }
      }
      div {
        &.bottom-margin {
          margin-bottom: 10px;
        }
      }
      .section-divide {
        h1 {
          font-size: 64px;
          margin-bottom: 0;
          margin-right: 6px;
        }
      }
      .heading-and-pic {
        margin-top: -24px;
        margin-bottom: 8px;
      }
    }
    div {
      &.bottom-margin {
        margin-bottom: 10px;
      }
    }
    &.more-margin-top {
      margin-top: 50px;
    }
    & > img {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &.mb10 {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
      &.mr {
        margin-right: 10px;
      }
    }
    img {
      &.mr {
        margin-right: 10px;
      }
    }
  }
  p {
    white-space: pre-wrap;
    &:last-child {
      margin-bottom: 0%;
    }
  }

  & > section {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
    p {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
  & > p:not(:last-child) {
    margin-bottom: 20px;
  }
  h2 {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    img {
      margin-right: 11px;
    }
    &.purple {
      color: $purple;
    }
  }
  img {
    max-width: 400px;
    &.dynamic-width {
      width: 100%;
      &.seventy {
        width: 70%;
      }
      &.ninety {
        width: 90%;
      }
    }
    &.width-xs {
      width: 51px;
    }
    &.width-s {
      width: 68px;
    }
    &.width-m {
      width: 98px;
    }
    &.mr.width-music {
      width: 44px;
      margin-left: 12px;
      margin-right: 24px;
    }
    &.width-person {
      width: 79px;
    }
    &.width-people {
      width: 72px;
    }
  }
  section {
    p:last-child {
      margin-bottom: 0;
    }
    small {
      white-space: pre-wrap;
    }
    & > img {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      align-self: center;
    }
  }
  & > img {
    align-self: center;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
