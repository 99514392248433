.pdf-article {
  .react-pdf__Document {
    .react-pdf__Page {
      width: 100vw;
      height: auto;
      max-width: 675px;
      .react-pdf__Page__canvas {
        width: 100% !important;
        height: 100% !important;
      }
      .react-pdf__Page__annotations {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}
