.24_132 {
  overflow: hidden;
}
.e24_132 {
  width: 428px;
  height: 1696px;
  position: absolute;
}
.e19_25 {
  background-color: rgba(255, 255, 255, 1);
  width: 428px;
  height: 1696px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.e19_26 {
  width: 428px;
  height: 1702px;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url(../images/victorian_2_bkg_border_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e19_27 {
  width: 48px;
  height: 49px;
  position: absolute;
  left: 39px;
  top: 20px;
  background-image: url(../images/victorian_2_logo_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e19_28 {
  color: rgba(229.00000154972076, 167.00000524520874, 71.00000336766243, 1);
  width: 258px;
  height: 29px;
  position: absolute;
  left: 50px;
  top: 92px;
  font-family: Noto Sans SC;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0;
}
.e19_29 {
  color: rgba(0, 0, 0, 1);
  width: 314px;
  height: 81px;
  position: absolute;
  left: 50px;
  top: 184px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.19_32 {
  border: 1px solid
    rgba(229.00000154972076, 167.00000524520874, 71.00000336766243, 1);
}
.e19_32 {
  width: 244px;
  height: 0px;
  position: absolute;
  left: 50px;
  top: 161px;
}
.e19_54 {
  color: rgba(0, 0, 0, 1);
  width: 210px;
  height: 17px;
  position: absolute;
  left: 50px;
  top: 121px;
  font-family: Noto Sans SC;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e19_55 {
  color: rgba(0, 0, 0, 1);
  width: 45px;
  height: 13px;
  position: absolute;
  left: 50px;
  top: 288px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e19_56 {
  color: rgba(0, 0, 0, 1);
  width: 32px;
  height: 16px;
  position: absolute;
  left: 141px;
  top: 344px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e19_57 {
  color: rgba(0, 0, 0, 1);
  width: 64px;
  height: 15px;
  position: absolute;
  left: 141px;
  top: 441px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e19_58 {
  color: rgba(0, 0, 0, 1);
  width: 86px;
  height: 15px;
  position: absolute;
  left: 141px;
  top: 532px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e24_115 {
  width: 51px;
  height: 60px;
  position: absolute;
  left: 71px;
  top: 418px;
  background-image: url(../images/vegetable_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e24_123 {
  width: 51px;
  height: 60px;
  position: absolute;
  left: 71px;
  top: 322px;
  background-image: url(../images/fruits_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e24_126 {
  width: 44px;
  height: 52px;
  position: absolute;
  left: 75px;
  top: 514px;
  background-image: url(../images/bread_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e19_59 {
  color: rgba(0, 0, 0, 1);
  width: 216px;
  height: 13px;
  position: absolute;
  left: 50px;
  top: 602px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e24_125 {
  width: 51px;
  height: 60px;
  position: absolute;
  left: 71px;
  top: 636px;
  background-image: url(../images/chicken_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e19_60 {
  color: rgba(0, 0, 0, 1);
  width: 134px;
  height: 16px;
  position: absolute;
  left: 141px;
  top: 653px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e24_120 {
  width: 51px;
  height: 60px;
  position: absolute;
  left: 71px;
  top: 732px;
  background-image: url(../images/seafood_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e19_61 {
  color: rgba(0, 0, 0, 1);
  width: 196px;
  height: 15px;
  position: absolute;
  left: 141px;
  top: 754px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e24_127 {
  width: 51px;
  height: 60px;
  position: absolute;
  left: 71px;
  top: 828px;
  background-image: url(../images/dairy_products_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e19_62 {
  color: rgba(0, 0, 0, 1);
  width: 196px;
  height: 15px;
  position: absolute;
  left: 141px;
  top: 854px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e24_118 {
  width: 51px;
  height: 60px;
  position: absolute;
  left: 71px;
  top: 924px;
  background-image: url(../images/peas_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e19_63 {
  color: rgba(0, 0, 0, 1);
  width: 187px;
  height: 32px;
  position: absolute;
  left: 141px;
  top: 941px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e19_64 {
  color: rgba(0, 0, 0, 1);
  width: 101px;
  height: 16px;
  position: absolute;
  left: 50px;
  top: 1020px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e24_119 {
  width: 51px;
  height: 60px;
  position: absolute;
  left: 71px;
  top: 1057px;
  background-image: url(../images/potato_chips_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e19_65 {
  color: rgba(0, 0, 0, 1);
  width: 93px;
  height: 16px;
  position: absolute;
  left: 141px;
  top: 1079px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e24_122 {
  width: 51px;
  height: 60px;
  position: absolute;
  left: 71px;
  top: 1153px;
  background-image: url(../images/sugar_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e19_66 {
  color: rgba(0, 0, 0, 1);
  width: 39px;
  height: 16px;
  position: absolute;
  left: 141px;
  top: 1171px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e24_121 {
  width: 51px;
  height: 60px;
  position: absolute;
  left: 64px;
  top: 1249px;
  background-image: url(../images/salt_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e19_67 {
  color: rgba(0, 0, 0, 1);
  width: 21px;
  height: 16px;
  position: absolute;
  left: 141px;
  top: 1271px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.e24_116 {
  width: 51px;
  height: 60px;
  position: absolute;
  left: 64px;
  top: 1345px;
  background-image: url(../images/liquor_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.e19_68 {
  color: rgba(0, 0, 0, 1);
  width: 42px;
  height: 16px;
  position: absolute;
  left: 141px;
  top: 1375px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
.19_83 {
  border: 1px solid
    rgba(229.00000154972076, 167.00000524520874, 71.00000336766243, 1);
}
.e19_83 {
  width: 244px;
  height: 0px;
  position: absolute;
  left: 50px;
  top: 1430px;
}
.19_84 {
  border: 1px solid
    rgba(229.00000154972076, 167.00000524520874, 71.00000336766243, 1);
}
.e19_84 {
  width: 244px;
  height: 0px;
  position: absolute;
  left: 50px;
  top: 1613px;
}
.e24_128 {
  width: 318px;
  height: 133px;
  position: absolute;
  left: 50px;
  top: 1455px;
}
.e19_51 {
  background-color: rgba(
    238.00000101327896,
    165.00000536441803,
    41.00000135600567,
    0.20000000298023224
  );
  width: 318px;
  height: 133px;
  position: absolute;
  left: 0px;
  top: 0px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.e19_69 {
  color: rgba(0, 0, 0, 1);
  width: 284px;
  height: 81px;
  position: absolute;
  left: 20px;
  top: 26px;
  font-family: Noto Sans;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
}
